// @use "sass:map";
// variables
$table-row-bg-color: rgba(0, 0, 0, 0.05);
// bulma variable overrides
$section-padding: 1rem;
$fullhd-enabled: false;
$table-row-hover-background-color: hsl(171, 100%, 41%);
$table-striped-row-even-background-color: $table-row-bg-color;
$table-head-cell-border-width: 0 0 1px;
$table-foot-cell-border-width: 0 0 1px;
$input-placeholder-color: hsl(0, 0%, 48%);

@import '../node_modules/bulma/bulma.sass';

#navbarMenu > a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
  color: #4a4a4a;
  background-color: white;
}

.card-content {
  padding: 0.5rem;
}

.table.is-hoverable tr:hover {
  color: white;
  font-weight: bold;
}

.card-header-title {
  color: hsl(0, 0%, 48%);
}

.table td,
.table th {
  vertical-align: middle;
}

.table td,
.table th {
  white-space: nowrap;
}

.has-covered {
  color: #00d1b2;
}

.has-not-covered {
  color: #f14668;
}

.is-flex-end {
  align-items: flex-end;
}

.is-absolute-bottom {
  position: absolute;
  bottom: 0;
}

.has-fontvar-allcaps {
  font-variant: all-small-caps;
}

.has-min-height-200vh {
  min-height: 200vh;
}

.table-pairs-striped tbody tr:nth-of-type(4n + 1),
.table-pairs-striped tbody tr:nth-of-type(4n + 2) {
  background: $table-row-bg-color;
}

.column.ranks-table-container:first-child tr:last-child {
  border-bottom: 1px solid $border;
}

@include mobile {
  .level:not(:last-child) {
    margin-bottom: 1rem;
  }

  .section {
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  .columns:last-child {
    margin-bottom: -0.5rem;
  }

  .columns {
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .column {
    padding: 0.5rem;
  }
}